var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "my-impact-card devices-height-100 gds-flex-container gds-flex-container--column gds-flex-container--top gds-flex-container--space-between"
    },
    [
      _c("div", { staticClass: "my-impact-card__header" }, [
        _c("img", { attrs: { src: _vm.impactIcon, alt: "" } }),
        _vm._v(" "),
        _c("span", { staticClass: "gds-desktop-only my-impact-card__title" }, [
          _vm._v(_vm._s(_vm.impact.name))
        ])
      ]),
      _vm._v(" "),
      _c("ImpactCardValue", { attrs: { impact: _vm.impact } }),
      _vm._v(" "),
      _c("div", { staticClass: "gds-mobile-only my-impact-card__title" }, [
        _vm._v(_vm._s(_vm.impact.name))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }