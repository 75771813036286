<template>
  <div class="gmp-devices-page__devices-list__device">
    <template v-if="device && device.deviceId">
        <router-link
          :to="`/devices/${device.deviceId}`"
        >
          <div class="devices-card">
            <section class="devices-card__image-container devices-background-blue-gradient">
              <img
                :src="device.imageURL"
                alt="Emporia EV Charger image"
              >
            </section>
            <section class="devices-card__text-container devices-padding-m">
              <div class="devices-color-black gds-font-size-l gds-font-medium">{{ device.deviceName }}</div>
              <div class="gds-font-size-s gds-text-grey gds-space-stack-s">{{ device.deviceType }}</div>
              <div
                v-if="statusIcon"
                class="status-icon-container"
              >
                <template v-if="statusIcon.isEventInProgress">
                  <EventInProgressIndicator />
                </template>
                <span v-if="statusIcon.isImage">
                  <img
                    alt="Status Icon"
                    class="status-icon"
                    :src="statusIcon.url"
                  >
                </span>
                <span class="gds-text-grey devices-font-size-xs devices-font-uppercase">{{ device.statusMessage.message }}</span>
              </div>
            </section>
          </div>
        </router-link>
    </template>
    <template v-if="!device || !device.deviceId">
        <router-link to="/devices/add-device">
          <section class="devices-card add-a-device padding-l">
            <div class="gds-button-circle add-icon gds-flex-container">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="35" fill="none">
                <path fill="#202020" fill-rule="evenodd" d="M17 16.5V7.1h-2v9.4H6v2.1h9V28h2v-9.4h9v-2h-9Z" clip-rule="evenodd"/>
              </svg>
            </div>
              <div class="devices-color-black gds-font-size-l gds-font-medium">Add a Device</div>
              <div class="gds-desktop-only gds-font-size-m gds-text-grey gds-align--text-center devices-width-60">
                Connect a battery or EV charger you own, or get one from GMP.
            </div>
          </section>
        </router-link>
    </template>
  </div>
</template>

<script>
import MediaQueryMixin from '../../../../../mixins/MediaQueryMixin';
import EventInProgressIndicator from '../../../shared/components/EventInProgressIndicator.vue';

export default {
  name: 'Device',
  components: {
    EventInProgressIndicator,
  },
  mixins: [
    MediaQueryMixin,
  ],
  props: {
    device: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      statusIcon: undefined,
    };
  },
  async mounted() {
    this.statusIcon = this.getStatusIcon(this.device);
  },
  methods: {
    getStatusIcon(device) {
      let config;
      const imagePathBase = '/wp-content/themes/gmptwentynineteen/assets/images/devices/';
      const statusMessage = device && device.statusMessage ? device && device.statusMessage : {};

      switch (statusMessage.type) {
      case 'event_in_progress':
        config = {
          isEventInProgress: true,
        };
        break;
      case 'device_offline':
        config = {
          isImage: true,
          url: `${imagePathBase}icon-devices-status-issue.svg`,
        };
        break;
      case 'event_scheduled':
        config = {
          isImage: true,
          url: `${imagePathBase}icon-devices-status-peak-event.svg`,
        };
        break;
      default:
        config = undefined;
      }

      return config;
    },
  },
};
</script>
