<template>
  <div
    class="gmp-devices-page__devices-list"
  >
    <Device
      v-for="device of devices"
      :key="device.deviceId"
      :device="device"
    />
    <Device />
  </div>
</template>

<script>
import Device from './device/Device.vue';
import MediaQueryMixin from '../../../../mixins/MediaQueryMixin';

export default {
  name: 'DevicesList',
  components: {
    Device,
  },
  mixins: [MediaQueryMixin],
  props: {
    devices: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>
