var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.impactConfig
    ? _c("div", [
        _c("div", { staticClass: "value-container" }, [
          _vm.impactConfig.isCount
            ? _c("div", { staticClass: "time" }, [
                _c(
                  "span",
                  {
                    staticClass: "hours font-largest devices-font-weight-light"
                  },
                  [_vm._v(_vm._s(_vm.impact.value))]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.impactConfig.isKwh
            ? _c("div", { staticClass: "time" }, [
                _c(
                  "span",
                  {
                    staticClass: "hours font-largest devices-font-weight-light"
                  },
                  [_vm._v(_vm._s(_vm.impact.value))]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "label devices-font-weight-light" }, [
                  _vm._v("kWh")
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.impactConfig.isDollars
            ? _c("div", { staticClass: "time" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "label devices-font-weight-light gds-font-size-xl"
                  },
                  [_vm._v("$")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "hours font-largest devices-font-weight-light"
                  },
                  [_vm._v(_vm._s(_vm.impact.value))]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.impactConfig.isHours
            ? _c("div", { staticClass: "time" }, [
                _c(
                  "span",
                  {
                    staticClass: "hours font-largest devices-font-weight-light"
                  },
                  [_vm._v(_vm._s(_vm.impactConfig.hoursConfig.hours))]
                ),
                _vm._v(" "),
                _vm.impactConfig.hoursConfig.hours == 1
                  ? _c(
                      "span",
                      { staticClass: "label devices-font-weight-light" },
                      [_vm._v("\n        hr\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.impactConfig.hoursConfig.hours > 1
                  ? _c(
                      "span",
                      { staticClass: "label devices-font-weight-light" },
                      [_vm._v("\n        hrs\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.impactConfig.hoursConfig.minutes
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "minutes font-largest devices-font-weight-light"
                      },
                      [_vm._v(_vm._s(_vm.impactConfig.hoursConfig.minutes))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.impactConfig.hoursConfig.minutes
                  ? _c(
                      "span",
                      { staticClass: "label devices-font-weight-light" },
                      [_vm._v("\n        min\n      ")]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }