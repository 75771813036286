<template>
  <div v-if="impactConfig">
    <div
      class="value-container"
    >
      <div
        v-if="impactConfig.isCount"
        class="time"
      >
        <span class="hours font-largest devices-font-weight-light">{{ impact.value }}</span>
      </div>
      <div
        v-if="impactConfig.isKwh"
        class="time"
      >
        <span class="hours font-largest devices-font-weight-light">{{ impact.value }}</span>
        <span class="label devices-font-weight-light">kWh</span>
      </div>
      <div
        v-if="impactConfig.isDollars"
        class="time"
      >
        <span class="label devices-font-weight-light gds-font-size-xl">$</span>
        <span class="hours font-largest devices-font-weight-light">{{ impact.value }}</span>
      </div>
      <div
        v-if="impactConfig.isHours"
        class="time"
      >
        <span class="hours font-largest devices-font-weight-light">{{ impactConfig.hoursConfig.hours }}</span>
        <span
          v-if="impactConfig.hoursConfig.hours == 1"
          class="label devices-font-weight-light"
        >
          hr
        </span>
        <span
          v-if="impactConfig.hoursConfig.hours > 1"
          class="label devices-font-weight-light"
        >
          hrs
        </span>
        <span
          v-if="impactConfig.hoursConfig.minutes"
          class="minutes font-largest devices-font-weight-light"
        >{{ impactConfig.hoursConfig.minutes }}</span>
        <span
          v-if="impactConfig.hoursConfig.minutes"
          class="label devices-font-weight-light"
        >
          min
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImpactCardValue',
  props: {
    impact: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      impactConfig: undefined,
    };
  },
  mounted() {
    this.impactConfig = this.getImpactConfig(this.impact);
  },
  methods: {
    getHoursAsObject(hours) {
      const isFloatingPoint = hours % 1 !== 0;
      const roundedHours = Math.floor(hours);
      const minutes = isFloatingPoint ? Math.round((hours % 1) * 60) : 0;

      return minutes
        ? { hours: roundedHours, minutes }
        : { hours: roundedHours };
    },
    getImpactConfig(impact) {
      const config = {};

      switch (impact.unit) {
      case 'hours':
        config.isHours = true;
        config.hoursConfig = this.getHoursAsObject(impact.value);
        break;
      case 'dollars':
        config.isDollars = true;
        break;
      case 'kWh':
        config.isKwh = true;
        break;
      default:
        config.isCount = true;
        break;
      }

      return config;
    },
  },
};
</script>
