<template>
  <div class="gmp-devices-page__my-impact">
    <div class="header-container gds-space-stack-l">
      <h1 class="my-account__title gds-space-stack-default">My Impact</h1>
    </div>
    <div
      v-if="!loadState"
      class="table-loading"
    />
    <flow-error
      v-if="loadState === 'error'"
      name="Impact information"
      state="unavailable"
      img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
    />
    <div
      v-if="impactsLoaded"
      class="gmp-devices-page__my-impact-list"
    >
      <ImpactCard
        v-for="impact of impacts"
        :key="impact.key"
        :impact="impact"
      />
    </div>
  </div>
</template>

<script>
import { startOfDay, endOfDay } from 'date-fns';
import GMPAPI from '../../../../../services/gmpapi';
import ImpactCard from './impactcard/ImpactCard.vue';
import { DumpError, ToServerDateTruncate } from '../../../../../utilities';

export default {
  name: 'MyImpactList',
  components: {
    ImpactCard,
  },
  data() {
    return {
      impacts: undefined,
      loadState: undefined,
      startDate: startOfDay(new Date(2024, 10, 1)),
      endDate: endOfDay(new Date()),
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    impactsLoaded() {
      return this.loadState === 'complete' && this.impacts && this.impacts.length;
    },
  },
  async mounted() {
    this.onDateRangeSelected();
  },
  methods: {
    onDateRangeSelected() {
      this.getImacts(this.currentAccount.accountNumber, this.getQueryString());
    },
    getQueryString() {
      return this.buildDateRangeQueryString(this.startDate, this.endDate);
    },
    buildDateRangeQueryString(startDate, endDate) {
      const startDateFormatted = ToServerDateTruncate(startDate);
      const endDateFormatted = ToServerDateTruncate(endDate);
      return `?startDate=${startDateFormatted}&endDate=${endDateFormatted}`;
    },
    async getImacts(accountNumber, queryString) {
      this.loadState = undefined;

      try {
        this.impacts = await GMPAPI.GetImapct(accountNumber, queryString);

        this.loadState = 'complete';
      } catch (err) {
        DumpError('getImacts error', err);
        this.loadState = 'error';
      }
    },
  },
};
</script>
