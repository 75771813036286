<template>
  <div
    v-if="FF_Devices"
    class="subpage-dashboards gmp-devices-page gmp-devices-page__temp-assets">
    <div>
      <div
        :class="{ 'is-mobile-or-tablet-view': !isDesktopView}"
      >
        <h2 class="my-account__title gds-space-stack-default">
          Temp Assets
        </h2>
      </div>
      <div class="gds-space-stack-s">
        <router-link to="/devices">
          &lt;&lt;Back to Devices Dashboard
        </router-link>
      </div>
      <div
        v-if="isDesktopView"
        :class="{ 'is-desktop-content': isDesktopView}"
      >
        DESKTOP CONTENT
      </div>
      <div
        v-if="!isDesktopView"
        :class="{ 'is-mobile-or-tablet-content': !isDesktopView}"
      >
        TABLET / MOBILE CONTENT
      </div>
    </div>
    <div>
      <!-- START leah content -->

      <!-- END leah content -->
      <!-- START kevin content -->
      <h2 class="my-account__title gds-space-stack-default">
        DevicesSelectControl DEMO
      </h2>
      <div class="gds-space-stack-l">
        <DevicesSelectControl
          :config="selectConfig1"
          :option-selected="onOptionSelected1"
        />
        <button @click="resetSelectConfig1">Modify Default</button>
        <span v-if="slectedOption1">You Selected: {{ slectedOption1 }}</span>
      </div>
      <div class="gds-space-stack-l">
        <DevicesSelectControl
          :config="selectConfig2"
          :option-selected="onOptionSelected2"
        />
        <button @click="resetSelectConfig2">Modify Default</button>
        <span v-if="slectedOption2">You Selected: {{ slectedOption2 }}</span>
      </div>
      <div class="gds-space-stack-l">
        <DevicesSelectControl
          :config="selectConfig3"
          :option-selected="onOptionSelected3"
        />
        <button @click="resetSelectConfig3">Change Config</button>
        <span v-if="slectedOption3">You Selected: {{ slectedOption3 }}</span>
      </div>
      <!-- END kevin content -->
      <!-- START todd content -->

      <!-- END todd content -->
      <!-- START ben content -->
      <div style="width: 175px;height: 40px;">
        <BatteryChargePercent :batteryPercentage=100 />
      </div>
      <div style="width: 250px;height: 100px;">
        <BatteryChargePercent :batteryPercentage=77 />
      </div>
      <!-- END ben content -->
      <!-- START jay content -->

      <!-- END jay content -->
    </div>
  </div>
</template>

<script>
import MediaQueryMixin from '../../mixins/MediaQueryMixin';
import { isFeatureEnabled } from '../../../services/featureflags';
import BatteryChargePercent from '../devices/shared/components/BatteryChargePercent.vue';
import DevicesSelectControl from '../devices/shared/components/DevicesSelectControl.vue';

export default {
  name: 'TempAssets',
  components: {
    DevicesSelectControl,
    BatteryChargePercent,
  },
  mixins: [MediaQueryMixin],
  data() {
    return {
      FF_Devices: undefined,
      slectedOption1: undefined,
      slectedOption2: undefined,
      slectedOption3: undefined,
      selectConfig1: {
        borderRadius: '0',
        selectedValue: 'oldest-first',
        options: [
          { label: 'Newest First', value: 'newest-first' },
          { label: 'Oldest First', value: 'oldest-first' },
        ],
      },
      selectConfig2: {
        width: '300px',
        selectedValue: 'last-6-months',
        options: [
          { label: 'Last 7 Days', value: 'last-7-days' },
          { label: 'Last 2 Weeks', value: 'last-2-weeks' },
          { label: 'Current Month', value: 'current-month' },
          { label: 'Last 6 Months', value: 'last-6-months' },
          { label: 'Year to Date', value: 'year-to-date' },
        ],
      },
      selectConfig3: {
        width: '300px',
        selectedValue: 'tuesday',
        options: [
          { label: 'Monday', value: 'monday' },
          { label: 'Tuesday', value: 'tuesday' },
          { label: 'Wednesday', value: 'wednesday' },
          { label: 'Thursday', value: 'thursday' },
          { label: 'Friday', value: 'friday' },
          { label: 'Saturday', value: 'saturday' },
          { label: 'Sunday', value: 'sunday' },
        ],
      },
      selectConfig4: {
        width: '300px',
        borderRadius: '0',
        selectedValue: 'bennington',
        options: [
          { label: 'Addison', value: 'addison' },
          { label: 'Bennington', value: 'bennington' },
          { label: 'Caledonia', value: 'caledonia' },
          { label: 'Chittenden', value: 'chittenden' },
          { label: 'Essex', value: 'essex' },
          { label: 'Franklin', value: 'franklin' },
          { label: 'Grand Isle', value: 'grand_isle' },
          { label: 'Lamoille', value: 'lamoille' },
          { label: 'Orange', value: 'orange' },
          { label: 'Orleans', value: 'orleans' },
        ],
      },
    };
  },
  computed: {
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    },
  },
  async mounted() {
    this.deviceId = this.$route.params.id;
    await this.checkFeatureFlag();
  },
  methods: {
    async checkFeatureFlag() {
      this.FF_Devices = await isFeatureEnabled('FF_Devices', false);

      if (!this.FF_Devices) {
        window.location = '/account';
      }
    },
    copyObject(objectToCopy) {
      return window.structuredClone(objectToCopy);
    },
    onOptionSelected1(selectedOption) {
      this.slectedOption1 = selectedOption;
    },
    onOptionSelected2(selectedOption) {
      this.slectedOption2 = selectedOption;
    },
    onOptionSelected3(selectedOption) {
      this.slectedOption3 = selectedOption;
    },
    resetSelectConfig1() {
      this.slectedOption1 = undefined;
      this.selectConfig1.selectedValue = 'newest-first';
      this.selectConfig1 = this.copyObject(this.selectConfig1);
    },
    resetSelectConfig2() {
      this.slectedOption2 = undefined;
      this.selectConfig2.width = '600px';
      this.selectConfig2.selectedValue = 'last-7-days';
      this.selectConfig2 = this.copyObject(this.selectConfig2);
    },
    resetSelectConfig3() {
      this.slectedOption3 = undefined;
      this.selectConfig3 = this.copyObject(this.selectConfig4);
    },
  },
};
</script>
