import { render, staticRenderFns } from "./ModalSimpleInfo.vue?vue&type=template&id=095a9103&scoped=true&"
import script from "./ModalSimpleInfo.vue?vue&type=script&lang=js&"
export * from "./ModalSimpleInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "095a9103",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bamboo/bamboo-agent-home/xml-data/build-dir/WWW-WWW-JOB1/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('095a9103')) {
      api.createRecord('095a9103', component.options)
    } else {
      api.reload('095a9103', component.options)
    }
    module.hot.accept("./ModalSimpleInfo.vue?vue&type=template&id=095a9103&scoped=true&", function () {
      api.rerender('095a9103', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/src/components/generic/ModalSimpleInfo.vue"
export default component.exports