<template>
  <figure class="event-in-progress-icon">
    <span class="event-in-progress-icon__inner" />
  </figure>
</template>

<script>
export default {
  name: 'EventInProgressIndicator',
};
</script>
