<template>
  <div
    v-if="FF_Devices"
    class="gmp-devices-page__overview"
  >
    <div>
      <div class="header-container gds-space-stack-l">
        <h1 class="my-account__title gds-space-stack-default">Devices</h1>
      </div>
      <div class="devices-overview-grid">
        <div
          class="devices-overview__widget devices-overview__widget__devices-list"
        >
          <div
            v-if="!loadState"
            class="table-loading"
          />
          <DevicesList
            v-if="isDevicesLoaded"
            :devices="devices"
          />
        </div>
        <div
          class="devices-overview__widget devices-overview__widget__my-impact-list"
        >
          <MyImpactList />
        </div>
        <div class="devices-overview__widget devices-overview__widget__status-history">
          status-history
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GMPAPI from '../../../../services/gmpapi';
import { DumpError } from '../../../../utilities';
import DevicesList from './deviceslist/DevicesList.vue';
import MyImpactList from './myimpactlist/MyImpactList.vue';
import MediaQueryMixin from '../../../mixins/MediaQueryMixin';
import { isFeatureEnabled } from '../../../../services/featureflags';

export default {
  name: 'DevicesOverview',
  components: {
    DevicesList,
    MyImpactList,
  },
  mixins: [MediaQueryMixin],
  data() {
    return {
      devices: undefined,
      loadState: undefined,
      FF_Devices: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    },
    isDevicesLoaded() {
      return this.loadState === 'complete' && this.devices && this.devices.length > 0;
    },
  },
  watch: {
    currentAccount() {
      this.getOverviewData(this.currentAccount.accountNumber);
    },
  },
  async mounted() {
    await this.checkFeatureFlag();

    if (this.currentAccount && this.currentAccount.accountNumber) {
      this.getOverviewData(this.currentAccount.accountNumber);
    }
  },
  methods: {
    goToDetailRoute(id) {
      this.$router.push({ path: `/devices/${id}` });
    },
    async getOverviewData(accountNumber) {
      this.loadState = undefined;

      try {
        const httpResponse = await Promise.all([
          GMPAPI.GetDevices(accountNumber),
        ]);

        this.loadState = 'complete';

        if (!httpResponse.length) {
          return;
        }

        [this.devices] = httpResponse;
      } catch (err) {
        DumpError('Get Overview Data error', err);
        this.loadState = 'error';
      }
    },
    async checkFeatureFlag() {
      this.FF_Devices = await isFeatureEnabled('FF_Devices', false);

      if (!this.FF_Devices) {
        window.location = '/account';
      }
    },
  },
};
</script>
