<template>
  <div
    class="my-impact-card devices-height-100 gds-flex-container gds-flex-container--column gds-flex-container--top gds-flex-container--space-between"
  >
    <div class="my-impact-card__header">
      <img
        :src="impactIcon"
        alt=""
      >
      <span class="gds-desktop-only my-impact-card__title">{{ impact.name }}</span>
    </div>
    <ImpactCardValue :impact="impact" />
    <div class="gds-mobile-only my-impact-card__title">{{ impact.name }}</div>
  </div>
</template>

<script>
import ImpactCardValue from './ImpactCardValue.vue';

export default {
  name: 'ImpactCard',
  components: {
    ImpactCardValue,
  },
  props: {
    impact: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      impactIcon: undefined,
    };
  },
  mounted() {
    this.impactIcon = this.getImpactIcon(this.impact.key);
  },
  methods: {
    getImpactIcon(key) {
      let iconFilename;
      const fileNameBase = 'icon-device-impact-';
      switch (key) {
      case 'gallon_of_gas_equivalent':
        iconFilename = `${fileNameBase}dollar.svg`;
        break;
      case 'avoided_power_disruptions':
        iconFilename = `${fileNameBase}battery-with-bolt.svg`;
        break;
      case 'peak_events_participated_in':
        iconFilename = `${fileNameBase}leaf.svg`;
        break;
      case 'battery_energy_to_grid_during_peak_events':
        iconFilename = `${fileNameBase}battery-full-bars.svg`;
        break;
      case 'battery_backup_power_provided':
        iconFilename = `${fileNameBase}clock.svg`;
        break;
      default:
        iconFilename = `${fileNameBase}ev-from-front.svg`;
        break;
      }

      return `/wp-content/themes/gmptwentynineteen/assets/images/devices/${iconFilename}`;
    },
  },
};
</script>
